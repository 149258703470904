.hero-section {
  height: var(--app-height);
  position: relative;
  overflow: hidden;

  .hero-section-bg {
    position: absolute;
    width: 100%;
    height: calc(100% + 100px);
    z-index: 0;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container, .row, .col-12 {
    height: 100%;
  }

  .col-12 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .circle-wrap {
      width: 9vw;
      position: absolute;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      transform: translateY(-12.5vw) translateX(-19vw);

      @media (max-width: $screen-lg) {
        width: max-content;
        transform: translateY(-180px) translateX(-240px);
      }

      @media (max-width: $screen-md) {
        transform: translateY(-150px) translateX(-120px);
      }

      img {
        width: 100%;
        animation-name: spin;
        animation-duration: 25000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @media (max-width: $screen-lg) {
          max-width: 140px;
        }

        @media (max-width: $screen-md) {
          max-width: 120px;
        }

        @media (max-width: $screen-sm) {
          max-width: 100px;
        }
      }
    }

    .img-wrap {
      overflow: hidden;

      @media (max-width: $screen-lg) {
        overflow: initial;
      }

      img {
        width: 25vw;

        @media (max-width: $screen-lg) {
          width: 100%;
          max-width: 400px;
        }

        @media (max-width: $screen-lg) {
          max-width: 320px;
        }

        @media (max-width: $screen-md) {
          max-width: 240px;
        }
      }
    }

    .scroll-wrap {
      font: 300 0.9375vw/1.2 $main-font;
      position: absolute;
      top: auto;
      left: auto;
      right: auto;
      bottom: 0;
      transform: translateY(-5.25vw);
      text-align: center;
      overflow: hidden;

      @media (max-width: $screen-lg) {
        font-size: 18px;
        transform: translateY(-60px);
      }

      @media (max-width: $screen-md) {
        transform: translateY(-40px);
      }

      .scroll-wrap-text, .scroll-wrap-img {
        overflow: hidden;
      }

      .scroll-wrap-img img {
        width: 1.2vw;

        @media (max-width: $screen-lg) {
          width: 23px;
        }
      }

      .scroll-wrap-text {
        margin-bottom: 1vw;

        @media (max-width: $screen-lg) {
          margin-bottom: 20px;
        }

        @media (max-width: $screen-md) {
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 0.9375vw;
        color: $color-white;

        @media (max-width: $screen-lg) {
          font-size: 16px;
        }
      }
    }
  }
}
