.listener-section {
  min-height: var(--app-height);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .listener-section-bg {
    position: absolute;
    width: 100%;
    height: calc(100% + 5.25vw);
    z-index: 0;
    top: 0;

    @media (max-width: $screen-lg) {
      height: calc(100% + 100px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container, .row, .col-12 {
    min-height: 100%;
  }

  .col-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .circle-wrap {
      width: 9vw;
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      transform: translateY(9.6vw) translateX(21.875vw);

      @media (max-width: $screen-lg) {
        width: max-content;
        transform: translateY(180px) translateX(320px);
      }

      @media (max-width: $screen-md) {
        transform: translateY(180px) translateX(270px);
      }

      @media (max-width: $screen-sm) {
        transform: translateY(140px) translateX(170px);
      }

      @media (max-width: $screen-xs) {
        transform: translateY(110px) translateX(130px);
      }

      img {
        width: 100%;
        animation-name: spin;
        animation-duration: 25000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @media (max-width: $screen-lg) {
          max-width: 140px;
        }

        @media (max-width: $screen-md) {
          max-width: 120px;
        }

        @media (max-width: $screen-sm) {
          max-width: 100px;
        }
      }
    }

    .headline-wrap {
      margin: 13vw auto 6.5vw;
      text-align: center;

      @media (max-width: $screen-lg) {
        margin: 250px auto 50px;
      }

      @media (max-width: $screen-sm) {
        margin: 200px auto 80px;
      }

      @media (max-width: $screen-xs) {
        margin: 180px auto 60px;
      }

      //.first-line, .second-line {
      //  overflow: hidden;
      //}

      .first-line {
        transform: translateX(-5.25vw);

        @media (max-width: $screen-lg) {
          transform: translateX(-100px);
        }

        @media (max-width: $screen-md) {
          transform: translateX(-50px);
        }

        @media (max-width: $screen-xs) {
          transform: translateX(-50px);
        }

        h2 {
          font-weight: 600;
        }
      }

      .second-line {
        margin-top: -2vw;
        transform: translateX(5.25vw);

        @media (max-width: $screen-lg) {
          margin-top: -40px;
          transform: translateX(100px);
        }

        @media (max-width: $screen-md) {
          margin-top: -25px;
          transform: translateX(50px);
        }

        @media (max-width: $screen-sm) {
          margin-top: -15px;
        }

        @media (max-width: $screen-xs) {
          margin-top: -10px;
          transform: none;
        }

        h2 {
          font-weight: 400;
        }
      }

      h2 {
        color: $color-white;

        &.bordered {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $color-white;
        }
      }
    }

    .waves-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 14vw;
      max-width: 100%;

      @media (max-width: $screen-lg) {
        margin-bottom: 300px;
      }

      @media (max-width: $screen-sm) {
        margin-bottom: 160px;
        flex-direction: column;
      }

      @media (max-width: $screen-xs) {
        margin-bottom: 80px;
      }

      > div {
        width: 50%;

        @media (max-width: $screen-sm) {
          width: 100%;
        }
      }

      .waves-img {

        @media (max-width: $screen-sm) {
          text-align: center;
          margin-bottom: 30px;
        }

        img, svg {
          width: 90%;
          margin-top: -100px;
          margin-left: -2vw;

          @media (max-width: $screen-lg) {
            max-width: 90%;
            margin-left: 0;
          }

          @media (max-width: $screen-sm) {
            max-width: 400px;
            margin-top: 0;
          }

          @media (max-width: $screen-xs) {
            max-width: 100%;
          }
        }
      }

      .waves-text p {
        color: $color-white;
        max-width: 42vw;

        @media (max-width: $screen-lg) {
          max-width: 540px;
        }
      }
    }

    .read-wrap {
      margin-bottom: 3.125vw;
      text-align: center;

      @media (max-width: $screen-lg) {
        margin-bottom: 40px;
      }

      @media (max-width: $screen-sm) {
        margin-bottom: 30px;
      }

      .read-wrap-text, .read-wrap-headline {
        overflow: hidden;
      }

      .read-wrap-text {
        margin: 0 auto 0.25vw;
        max-width: 36.5vw;

        @media (max-width: $screen-lg) {
          margin: 0 auto 5px;
          max-width: 700px;
        }

        p {
          color: $color-white;
        }
      }

      .read-wrap-headline {
        max-width: 52.8vw;
        margin: 0 auto 1.3vw;

        @media (max-width: $screen-lg) {
          max-width: 1015px;
          margin: 0 auto 25px;
        }

        h3 {
          color: $color-white;

          span {
            color: #2D6ABD;
          }
        }
      }

      .btn-read {
        font: 300 0.9375vw/1.75 $main-font;
        display: inline-flex;
        margin: 0 auto;
        color: #2D6ABD;
        text-decoration: none;
        transition: $fast-transition;

        @media (max-width: $screen-lg) {
          font-size: 18px;
        }

        @media (max-width: $screen-sm) {
          font-size: 16px;
        }

        &:hover {
          transform: scale(1.1);
        }

        span {
          display: inline-flex;
        }

        img {
          width: 1.65vw;
          margin-left: 0.415vw;

          @media (max-width: $screen-lg) {
            width: 32px;
            margin-left: 8px;
          }

          @media (max-width: $screen-sm) {
            width: 28px;
          }
        }
      }
    }
  }
}
