footer {
  position: fixed;
  z-index: 10;
  bottom: 115px;
  left: -110px;
  padding: 5.25vw 3.35vw;
  rotate: -90deg;

  @media (max-width: $screen-lg) {
    padding: 75px 45px;
    display: none;
  }

  @media (max-width: $screen-md) {
    padding: 50px 30px;
  }

  @media (max-width: $screen-sm) {
    padding: 20px 25px;
  }

  .list-wrap {
    overflow: hidden;
  }

  ul {
    list-style-type: none;
    height: 30px;
    margin: 0;
    padding: 30px 0 0;

    li {
      font: 300 18px/1.2 $main-font;
      color: $color-white;
      padding: 4px 0;
      max-width: max-content;

      @media (max-width: $screen-md) {
        font-size: 16px;
      }

      span {
        display: inline-flex;
      }

      .num:not(.active), .between {
        color: #898989;
      }

      .between {
        margin: 0 5px;
      }

      .name {
        margin-left: 20px;
      }
    }
  }
}
