.form-section {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  height: var(--app-height);
  transition: all 0.75s cubic-bezier(0.75, 0, 1, 0.6);
  transition-timing-function: cubic-bezier(0.75, 0, 1, 0.6);

  @media (max-width: $screen-sm) {
    width: 100vw;
  }

  &.hidden {
    right: -700px;

    @media (max-width: $screen-sm) {
      right: -100vw;
    }

    .overlay {
      display: none;
    }
  }

  .close-icon-wrap {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 11;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $screen-sm) {
      top: 25px;
      right: 25px;
    }

    .icon {
      display: block;
      width: 30px;
      height: 2px;
      margin: 0;
      position: absolute;
      background-color: $color-white;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }

  form {
    position: relative;
    z-index: 10;
    min-height: 100%;
    min-width: 600px;
    padding: 70px;
    background-color: $color-main;
    max-height: var(--app-height);
    overflow-y: auto;

    @media (max-width: $screen-sm) {
      min-width: 300px;
      padding: 50px 30px;
    }

    .form-group {
      margin-bottom: 30px;

      label {
        font: 600 12px/1.2 $main-font;
        text-transform: uppercase;
        color: rgba($color-white, 0.8);
        margin: 0;
      }

      input, textarea {
        box-shadow: none;
        border-radius: 0;
        border: 0;
        padding: 0;
        color: $color-white;
        background-color: transparent;
        border-bottom: 1px solid rgba($color-white, 0.6);

        &:hover, &:focus, &:active {
          border-bottom: 1px solid $color-white;
        }
      }

      input {
        height: 48px;
      }

      textarea {
        height: 100px;
        resize: none;
      }
    }

    .btn-form {
      font: 600 14px/1.2 $main-font;
      color: $color-main;
      border: 0;
      padding: 15px 30px;
      text-transform: uppercase;
      background-color: $color-white;

      &.disabled {
        cursor: none;
        opacity: .5;
        pointer-events: none !important;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: var(--app-height);
    width: 100vw;
    z-index: 9;
    background-color: rgba($color-black, .6);
  }
}
