@import "assets/scss/variables";
@import "assets/scss/loading";
@import "assets/scss/header";
@import "assets/scss/hero";
@import "assets/scss/listener";
@import "assets/scss/about";
@import "assets/scss/contact";
@import "assets/scss/form";
@import "assets/scss/notfound";
@import "assets/scss/footer";

* {
  margin: 0;
}

:root {
  --app-height: 100vh;
}

html, body {
  overscroll-behavior-y: contain;
  width: 100%;
  height: 100%;
}

body {
  font-family: $main-font;
  background-color: $color-main;
  overflow-x: hidden;

  &.hidden {
    overflow: hidden;
  }
}

header, main, footer {
  overflow-x: hidden;
  max-width: 100vw;
}

//#root {
//  position: fixed;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  overflow-y: auto;
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  user-select: none;
//}

main section {
  overflow: hidden;
  transition: none;
  background-color: $color-main;
}

.container {
  @media (max-width: $screen-xs) {
    padding: 0 25px !important;
  }

  @media (min-width: $screen-xs) {
    max-width: 100% !important;
    padding: 0 15px !important;
  }

  @media (min-width: $screen-lg) {
    max-width: 1300px !important;
  }

  @media (min-width: $screen-xl) {
    max-width: 72vw !important;
  }

  @media (min-width: $screen-xxl) {
    max-width: 80vw !important;
  }
}

a {
  cursor: pointer;
}

.page-scroll-section {
  width: 5px;
  height: 100%;
  background-color: $color-main;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;

  @media (max-width: $screen-sm) {
    width: 3px;
  }
}

.page-scroll-bar {
  height: 0;
  width: 5px;
  background-color: $color-white;

  @media (max-width: $screen-sm) {
    width: 3px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.invisible {
  opacity: 0;
  transition: $fast-transition;
}
