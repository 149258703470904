.about-section {
  min-height: var(--app-height);
  position: relative;
  overflow: hidden;
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-main;

  @media (max-width: $screen-md) {
    padding: 50px 0;
    min-height: auto;
  }

  @media (max-width: $screen-sm) {
    padding: 30px 0;
  }

  .container, .row, .col-6 {
    min-height: var(--app-height);

    @media (max-width: $screen-md) {
      min-height: auto;
    }
  }

  .container {
    display: flex;
    align-items: center;
  }

  .col-12 {
    position: relative;
  }

  .col-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: $screen-md) {
      display: block;
    }

    .img-wrap {
      right: 0;
      height: 100%;
      width: 50vw;
      position: absolute;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: $screen-md) {
        display: initial;
        height: auto;
        margin-bottom: 30px;
        position: relative;
        width: 100%;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;

        @media (max-width: $screen-md) {
          object-fit: initial;
          height: auto;
          width: 100%;
          max-width: 500px;
        }
      }
    }
  }

  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-white;
    padding: 10vw 0 6.75vw 5.25vw;
    position: relative;
    z-index: 2;

    @media (max-width: $screen-xxl) {
      padding: 120px 20px 120px 60px;
    }

    @media (max-width: $screen-lg) {
      padding: 15px 15px 15px 30px;
    }

    @media (max-width: $screen-md) {
      padding: 15px 15px 0 15px;
    }

    .headline-wrap {
      margin-bottom: 2.85vw;

      @media (max-width: $screen-lg) {
        margin-bottom: 30px;
      }

      h3 {

        &:first-child {
          font: 500 2.5vw/1.2 $main-font;

          @media (max-width: $screen-lg) {
            font-size: 36px;
          }

          @media (max-width: $screen-md) {
            font-size: 32px;
          }

          @media (max-width: $screen-sm) {
            font-size: 24px;
          }
        }

        &:last-child {
          font: 300 2.5vw/1.2 $headline-font;
          margin-top: -0.525vw;

          @media (max-width: $screen-lg) {
            font-size: 36px;
            margin-top: -10px;
          }

          @media (max-width: $screen-md) {
            font-size: 32px;
            margin-top: 0;
          }

          @media (max-width: $screen-sm) {
            font-size: 24px;
          }
        }
      }
    }

    .text-wrap {
      padding-left: 11.5vw;

      @media (max-width: $screen-xl) {
        padding-left: 0;
      }

      .headline, .text {
        overflow: hidden;
      }

      .headline {
        margin-bottom: 1.85vw;

        @media (max-width: $screen-lg) {
          margin-bottom: 35px;
        }

        @media (max-width: $screen-md) {
          margin-bottom: 25px;
          max-width: 500px;
        }

        h4 {
          margin: 0;
        }
      }

      .text {
        max-width: 21.5vw;

        @media (max-width: $screen-xl) {
          max-width: 100%;
        }
      }
    }
  }
}
