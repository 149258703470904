@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://use.typekit.net/agv5opm.css');

// Typography
$main-font: 'Nunito Sans', sans-serif;
$headline-font: scotch-display, sans-serif;

body {

  h2 {
    font: 800 7.8vw/1.2 $main-font;

    @media (max-width: $screen-lg) {
      font-size: 120px;
    }

    @media (max-width: $screen-md) {
      font-size: 90px;
    }

    @media (max-width: $screen-sm) {
      font-size: 60px;
    }

    @media (max-width: $screen-xs) {
      font-size: 48px;
    }
  }

  h3 {
    font: 500 2.5vw/1.2 $headline-font;

    @media (max-width: $screen-lg) {
      font-size: 36px;
    }

    @media (max-width: $screen-md) {
      font-size: 32px;
    }

    @media (max-width: $screen-sm) {
      font-size: 24px;
    }
  }

  h4 {
    font: 300 1.875vw/1.2 $headline-font;

    @media (max-width: $screen-lg) {
      font-size: 32px;
    }

    @media (max-width: $screen-md) {
      font-size: 24px;
    }

    @media (max-width: $screen-sm) {
      font-size: 20px;
    }

    @media (max-width: $screen-xs) {
      font-size: 18px;
    }
  }

  p {
    font: 300 1.15vw/1.4 $main-font;

    @media (max-width: $screen-lg) {
      font-size: 18px;
    }

    @media (max-width: $screen-sm) {
      font-size: 16px;
    }
  }

  h1, h2, h3, p {
    margin: 0;
  }
}
