@import "variables";

.loading-section {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--app-height);
  width: 100vw;
  z-index: 100;
  background-color: $color-main;
  transition: all 0.75s cubic-bezier(0.75, 0, 1, 0.6);
  transition-timing-function: cubic-bezier(0.75, 0, 1, 0.6);

  .loading-counter {
    font: 400 50px/1 $headline-font;
    color: $color-white;

    @media (max-width: $screen-lg) {
      font-size: 40px;
    }
  }

  &.hidden {
    transform: translateY(-120vh);
  }
}
