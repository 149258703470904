.contact-section {
  min-height: var(--app-height);
  position: relative;
  overflow: hidden;
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;

  .contact-section-bg {
    position: absolute;
    width: 100%;
    height: calc(100% + 100px);
    z-index: 0;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container, .row, .col-12 {
    height: 100%;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .col-12 {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $screen-md) {
      justify-content: center;
    }
  }

  .text-wrap {
    padding: 20vw 0 14.5vw;
    max-width: 37.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $screen-xxl) {
      padding: 150px 15px;
      max-width: 720px;
    }

    @media (max-width: $screen-lg) {
      padding: 120px 60px;
    }

    @media (max-width: $screen-sm) {
      padding: 60px 0;
    }

    .headline-wrap {
      text-align: right;

      @media (max-width: $screen-xs) {
        text-align: left;
      }

      .first-line h2 {
        font-weight: 600;
      }

      .second-line {
        margin-top: -2.1vw;

        @media (max-width: $screen-lg) {
          margin-top: -40px;
        }

        @media (max-width: $screen-md) {
          margin-top: -20px;
        }

        @media (max-width: $screen-sm) {
          margin-top: -10px;
        }

        h2 {
          font-weight: 300;
          font-family: $headline-font;
        }
      }

      h2 {
        color: $color-white;

        &.bordered {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $color-white;
        }
      }
    }

    .content-wrap, .link-wrap {
      overflow: hidden;
    }

    .content-wrap {
      max-width: 23.45vw;
      margin-top: -4.6875vw;
      margin-bottom: 1.3vw;

      @media (max-width: $screen-lg) {
        max-width: 360px;
        margin-top: -90px;
        margin-bottom: 25px;
      }

      @media (max-width: $screen-md) {
        max-width: 300px;
        margin-top: -70px;
      }

      @media (max-width: $screen-sm) {
        margin-top: 20px;
        max-width: 290px;
      }

      @media (max-width: $screen-xs) {
        max-width: 100%;
      }

      p {
        font-size: 1.25vw;
        color: $color-white;

        @media (max-width: $screen-lg) {
          font-size: 18px;
        }

        @media (max-width: $screen-sm) {
          font-size: 16px;
        }

        span {
          font-weight: 700;
        }
      }
    }

    .btn-get {
      font: 300 1.15vw/1.45 $main-font;
      text-decoration: underline;
      color: $color-white;

      @media (max-width: $screen-lg) {
        font-size: 18px;
      }

      @media (max-width: $screen-sm) {
        font-size: 16px;
      }
    }
  }
}
