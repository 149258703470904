header {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  transition: $med-transition;

  &.bg {
    background-color: rgba($color-main, .75);
  }

  .navbar {
    padding: 3.35vw 5.25vw;

    @media (max-width: $screen-lg) {
      padding: 45px 75px;
    }

    @media (max-width: $screen-md) {
      padding: 25px 30px;
    }

    @media (max-width: $screen-sm) {
      padding: 25px 20px;
    }

    > * {
      position: relative;
      z-index: 10;
    }
  }

  .navbar-medium-wrap {
    min-width: 150px;display:none;

    @media (max-width: $screen-md) {
      min-width: auto;
    }

    &:hover {

      .icons-wrap {
        transform: none;
        border-color: transparent;

        .icon-bar {

          &:nth-child(1) {
            opacity: 1;
          }

          &:nth-child(3) {
            opacity: 1;
          }
        }
      }

      p {
        margin-left: 15px;
      }
    }

    .btn-main {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .icons-wrap {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $color-white;
      transform: translateY(0) rotate(-60deg);
      transition: $fast-transition;

      @media (max-width: $screen-sm) {
        width: 36px;
        height: 36px;
      }

      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        margin: 0;
        position: absolute;
        background-color: $color-white;
        transition: opacity, transform;
        transition-duration: .3s;
        transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);

        &:nth-child(1) {
          width: 11px;
          margin-bottom: 7px;
          transform: translateX(7px) rotate(45deg);
          opacity: 0;
        }

        &:nth-child(3) {
          width: 11px;
          margin-top: 7px;
          transform: translateX(7px) rotate(-45deg);
          opacity: 0;
        }
      }
    }

    p {
      font: 300 18px/1.2 $main-font;
      color: $color-white;
      margin-left: 25px;
      transition: $fast-transition;

      @media (max-width: $screen-md) {
        font-size: 16px;
        margin-left: 15px;
      }
    }
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    font-size: 12px;

    img {

      @media (max-width: $screen-sm) {
        max-width: 60px;
      }
    }
  }

  .navbar-contact-wrap {
    min-width: 150px;
    text-align: right;

    @media (max-width: $screen-md) {
      display: flex;
      align-items: center;
      min-width: auto;
    }

    a {
      font: 300 18px/1.2 $main-font;
      color: $color-white;
      text-decoration: none;

      @media (max-width: $screen-md) {
        font-size: 16px;
      }
    }
  }
}
